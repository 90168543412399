import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button'
import clsx from 'clsx';

const useStyles = makeStyles({
    easyHeadingColor: {
        color: '#279bce',
    },
    fontFamily: {
        fontFamily: 'Montserrat !important',
    },
    box: {
        width: '60vw',
        height: '80vh',
        boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.16)',
        backgroundColor: '#ffffff',
    },
    blueLine: {
        width: '60vw',
        height: '8px',
        backgroundColor: '#2ba3d7',
    },
    fillInfo: {
        width: '60vw',
        height: '50px',
        opacity: '0.1',
        backgroundColor: '#2ba3d7',
    },
    fillInfoText: {
        fontSize: '16px',
        fontWeight: '600',
        lineHeight: '1.19',
        color: '#2ba3d7',
        position: 'relative',
        bottom: '35px'
    },
    buttonCss: {
        margin: '65px 10px 40px 5px',
        width: '100px',
        fontWeight:'600',
        fontSize:'14px',
        height: '40px', 
        borderRadius: '3px',
        backgroundImage: 'linear-gradient(280deg ,#69ccd9 , #2ba3d7 )',
        
    },
    powered: {
        position:'relative',
        height: '40px',
        color: '#fff',
        paddingTop: '20px',
        backgroundColor: '#555f8d',
        bottom: '-50px',
    },
    uploadBox:{
        width: '328px',
        height: '56px',
        borderRadius: '2px',
        border: 'dashed 2px rgba(85, 95, 141, 0.5)',
        backgroundColor: 'rgba(85, 95, 141, 0.1)',
    }
});


export default function UploadFile(props) {
    const classes = useStyles();
    
    const onSubmitUpload = (e)=>{
        e.preventDefault()
        props.setDisplay({...props.display,uploadFile:false,success:true})

        console.log("Outer Values")
        // props.searchValue(stateSearch)
        // setstateSearch(e.target.value)
        // if(event.key === 'Enter'){
            // console.log(...props.display)
            //    if(event.target.value == 'troy' && event.target.value == 'delhi'){
            //        console.log("inner Values")
            //    }

            //    return <Maps/>
        // }
    }

    return (
        <Box className={clsx(classes.box)}  marginTop={5} spacing={3} align="center">
        <img width="160px" src="https://cdn.zeplin.io/5f3a0bd1d8d64467967b3a48/assets/24C7C6D6-85E4-4304-B709-9E97C3BFCA37.png"/>
            <Box>
                <Box className={classes.blueLine}></Box>
                <Box className={classes.fillInfo}></Box>
                <Typography className={clsx(classes.fillInfoText, classes.fontFamily)}>
                    Upload File
                    </Typography>
                

            </Box>
            <Box marginTop={5}>
                <Button className={classes.uploadBox}>Upload File</Button>
            </Box>

            <Button className={classes.buttonCss} onClick={onSubmitUpload}>Submit</Button>

            <Box className={classes.powered}>
                <Typography className={classes.fontFamily}>Powered by Mapzot</Typography>
            </Box>
        </Box>
    );

};