
import validator from "validator";
import _ from "lodash"

export const validateRegisterInput = (data) => {
    let errors = {};
    data.firstname = !_.isEmpty(data.firstname) ? data.firstname : "";
    data.company = !_.isEmpty(data.company) ? data.company : "";
    data.lastname = !_.isEmpty(data.lastname) ? data.lastname : "";
    data.userEmail = !_.isEmpty(data.userEmail) ? data.userEmail : "";
    data.mobile = !_.isEmpty(data.mobile) ? data.mobile : "";
   data.title = !_.isEmpty(data.title) ? data.title : "";
//    data.checkbox = !_.isEmpty(data.checkbox) ? data.checkbox : "";

    if (
        !validator.isLength(data.firstname, {
            min: 2,
            max: 30
        })
    ) {
        errors.name = "Name must be between 2 and 30 characters";
    }

    if (validator.isEmpty(data.firstname)) {
        errors.name = "First Name Field is required";
    }
    if (validator.isEmpty(data.lastname)) {
        errors.lastname = "Last Name Field is required";
    }
    if (!validator.isEmail(data.userEmail)) {
        errors.email = "Email is invalid";
    }
    if (validator.isEmpty(data.company)) {
        errors.company = "Company Name Field is required";
    }
    if (validator.isEmpty(data.userEmail)) {
        errors.email = "Email Field is required";
    }

    // if (!validator.isMobilePhone(data.mobile, ["en-US", "en-IN",])) {
    //     errors.mobile = "Invalid mobile no.";
    // }

    // if (validator.isEmpty(data.mobile)) {
    //     errors.mobile = "Mobile no. is required";
    // }

    if (validator.isEmpty(data.title)) {
        errors.title = "Title is required";
    }

    if (!data.checkbox) {
        errors.checkbox = "Please accept the terms and condiitions";
    }
    


   

    return {
        errors,
        isValid: _.isEmpty(errors)
    };
};