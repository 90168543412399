import React, { useRef, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import { positions } from '@material-ui/system';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button'
import clsx from 'clsx';

const useStyles = makeStyles({
    easyHeadingColor: {
        color: '#279bce',
    },
    fontFamily: {
        fontFamily: 'Montserrat, sans-serif !important',
    },
    mapWidth: {
        width: '60vw',
        height: '63vh',
    },
    box: {
        width: '60vw',
        height: '65vh',
        display: 'block',
        margin: '0 auto',
        // boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.16)',
        backgroundColor: '#ffffff',
    },
    powered: {
        position: 'relative',
        height: '40px',
        color: '#fff',
        paddingTop: '20px',
        backgroundColor: '#555f8d',
        bottom: '-135px',
    },
    height: {
        height: '100%',
    },
    cancelButtn: {
        position: 'relative',
        top: '-90px',
        textTransform: 'none',
        fontWeight: '600',
        fontSize: '14px',
        padding: '10px 20px',
        borderRadius: '3px',
        backgroundImage: 'linear-gradient(280deg ,#69ccd9 , #2ba3d7 )',
    },
    buttngrp: {
        transform: 'translate(118%, 140%)',
        position: 'relative',
        zIndex: '1',
        borderRadius: '3px',
        backgroundColor: '#2ba3d7',
    },
    colorBtn: {
        color: '#fff',
        fontSize: '12px',
        textTransform: 'none',

    },
    colorBtnSate: {
        backgroundColor: '#fff',
        color: '#000',
        fontSize: '12px',
        textTransform: 'none',
    },
    marginTop: {
        marginTop: '150px',
    }
});





export default function EasySubmit(props) {
    const classes = useStyles();
    const [mapId, setMapId] = useState('roadmap');
    const [newPolygon, setNewPolygon] = useState()
    const [LatLang, setLatLang] = useState({});
    const [strokeColor,setStrokeColor]=useState('#FF0000');
    const [fillColor,setColor]=useState('#FF0000');
        const google = window.google;

    var map;
    var drawingManager;
    var visiblePolygon;

    useEffect(() => {
        function initMap() {
            let lat;
            let lng;
            let coord = [
                { lat: 43.002013953541585, lng: -85.5937492524778 },
                { lat: 43.00205279081586, lng: -85.59289094559304 },
                { lat: 43.00063244024497, lng: -85.59288558117501 },
                { lat: 43.00029399249129, lng: -85.59292313210122 },
                { lat: 43.00026070244757, lng: -85.59384044758431 },
                { lat: 43.00205279081586, lng: -85.59386190525643 }

            ]
            if (props.value === '2183 East Beltline Avenue Northeast, Grand Rapids, MI, USA') {
                lat = props.latlng[0].lat;
                lng = props.latlng[0].lng;
            }
            else if (props.value === '3986 John R Road, Troy, MI, USA') {
                lat = props.latlng[1].lat;
                lng = props.latlng[1].lng;
            }
            else {
                lat = 43.0020498;
                lng = -85.5935092;
            }
            map = new google.maps.Map(document.getElementById("map"), {
                center: { lat: lat, lng: lng },
                zoom: 16,
                mapTypeId: 'satellite',
                disableDefaultUI: true
            });
            new google.maps.Marker({
                position: { lat, lng },
                map: map,
            })
            // drawPolygon()
            alreadyPolygon(coord,strokeColor,fillColor);
            if(strokeColor ==='#2ba3d7'){
                setTimeout(() => {
                    props.setDisplay({
                        ...props.display, uploadFile: true,
                        thankyou: true, map: false,
                    })
                }, 3000);
            }
        }
        initMap()
    }, [strokeColor,fillColor])

    function alreadyPolygon(dataArray,strokeColor,fillColor){
        var alreadyPolygonOnMap = new google.maps.Polygon({
            paths: dataArray,
            strokeColor,
            strokeOpacity: 0.8,
            strokeWeight: 3,
            fillColor,
            fillOpacity: 0.35,
            // editable:true,
        });
        alreadyPolygonOnMap.setMap(map)

        google.maps.event.addListener(alreadyPolygonOnMap, 'click', function (polygon) {
            setStrokeColor('#2ba3d7')
            setColor('#2ba3d7')
        });
    }

   




    
    function drawPolygon() {
        drawingManager = new google.maps.drawing.DrawingManager({
            drawingMode: google.maps.drawing.OverlayType.POLYGON,
            drawingControl: false,
            polygonOptions: {
                strokeColor: '#ff6868',
                strokeOpacity: 1,
                strokeWeight: 2,
                fillColor: '#e48686',
                fillOpacity: 0.35,
                zIndex: 1
            }
        });
        drawingManager.setMap(map);

        google.maps.event.addListener(drawingManager, 'polygoncomplete', function (polygon) {
            visiblePolygon = polygon
            console.log(polygon)
            const coords = polygon.getPath().getArray().map(coord => {
                return {
                    lat: coord.lat(),
                    lng: coord.lng()
                }
            });
            drawingManager.setMap(null);
            props.setDisplay({
                ...props.display, uploadFile: true,
                thankyou: true, map: false,
            })


            console.log(drawingManager)
            console.log(coords);
        });
    }

    function clearSelection() {
        drawingManager.setMap(map);
        visiblePolygon.setMap(null);
        drawPolygon()
        // console.log(drawingManager.setMap(null));
    }

    return (
        <Box className={clsx(classes.box)} spacing={3} align="center">

            <ButtonGroup className={clsx(classes.buttngrp, classes.fontFamily)} aria-label="large outlined button group">
                <Button className={classes.colorBtn}>Map</Button>
                <Button className={classes.colorBtnSate}>Satellite</Button>
                <Button className={classes.colorBtnSate}>StreetView</Button>
            </ButtonGroup>

            <Box id='map' className={classes.mapWidth} />
            <Button className={classes.cancelButtn} onClick={() => clearSelection()}>Cancel</Button>
            <Box className={classes.powered} position="relative" top={-44}>
                <Typography className={classes.fontFamily}>Powered by Mapzot</Typography>
            </Box>

        </Box>
    );

};