import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button'
import clsx from 'clsx';
import Maps from './Maps'
import { typography } from '@material-ui/system';

const useStyles = makeStyles({
    easyHeadingColor: {
        color: '#279bce',
    },
    blueLine: {
        width: '60vw',
        height: '8px',
        backgroundColor: '#2ba3d7',
    },
    fillInfo: {
        width: '60vw',
        height: '50px',
        opacity: '0.1',
        backgroundColor: '#2ba3d7',
    },
    fillInfoText: {
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: '1.19',
        color: '#2ba3d7',
        position: 'relative',
        bottom: '35px'
    },
    fontFamily: {
        fontFamily: 'Montserrat, sans-serif !important',
    },
    input: {
        width: '45vw',
        height: '46px',
        paddingLeft: '55px',
        borderRadius: '25px',
        outline: 'none',
        border: 'solid 1px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#ffffff',
    },
    marginTop: {
        paddingTop: '50px',
    },
    box: {
        width: '60vw',
        height: '70vh',
        boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.16)',
        backgroundColor: '#ffffff',
    },
    inputSearch: {
        position: 'absolute',
        left: '330px',
        objectFit: 'contain',
        opacity: '0.37',
        padding: '14px 10px 10px 10px !important',
    },
    paddingMapzot:{
        padding : '4px 0px 0px 4px',
        fontWeight:'600',
    },
    marginLogo:{
           margin:'0px 1px 0px -10px',
    },
    powered: {
        position: 'relative',
        height: '40px',
        color: '#fff',
        paddingTop: '20px',
        backgroundColor: '#555f8d',
        bottom: '-135px',
    },
    verifiedBox:{
        position:'relative',
        top:'110px',
        display:'flex',
        justifyContent:'center',
    },
    italic:{
        fontSize:'14px',
        fontStyle:'italic'
    }
});


export default function EasySubmit(props) {
    const [stateSearch, setstateSearch] = useState("")
    const classes = useStyles();

    const inputChange = (e) => {
        setstateSearch(e.target.value)
    }

    const click =(e)=>{
        if(props.firsttimeClicked){
        e.preventDefault()
        console.log(props.setOTP)
        props.setDisplay({ ...props.display, fillTheInfo: true, easySubmit: false })
        }
        else{
            setstateSearch('2183 E Beltline Avenue Northeast, Grand Rapids, MI, USA')
        }

        props.setZoom((prev) => !prev)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        console.log("Outer Values")
        props.setDisplay({ ...props.display, map: true, easySubmit: false })
        props.searchValue(stateSearch)
    }

    return (
        <Box className={classes.box} marginTop={10} spacing={3} align="center">
            <Box display="flex" justifyContent="center" paddingTop={2}paddingBottom={2} color="#2ba3d7">
                <img className={classes.marginLogo} width ="40px"src="https://cdn.zeplin.io/5f3a0bd1d8d64467967b3a48/assets/0DEDADE4-4C3D-4C9D-A93D-5D47BC1D475D.png"
                    alt="logo" />
                <Typography className={clsx(classes.paddingMapzot, classes.fontFamily)} variant="h5" color="#2ba3d7">Mapzot</Typography>
            </Box>
            <Box className={classes.blueLine}></Box>
            <Box className={classes.fillInfo}></Box>
            <Typography className={clsx(classes.fillInfoText, classes.fontFamily)}>
                Quick Site Selection
            </Typography>

            <Box marginTop={10} >
                <form onSubmit={onSubmit}>
                    <input type="text"
                    autoFocus
                        value={stateSearch}
                        onClick={click}
                        onChange={inputChange}
                        className={clsx(classes.input, classes.fontFamily)}
                        placeholder="Search address..."></input>
                    <Button className={classes.inputSearch}>
                        <img
                            src="https://cdn.zeplin.io/5f3a0bd1d8d64467967b3a48/assets/7B608277-0B8A-4E95-A783-8463F3727FEE.svg" alt="search" />
                    </Button>
                </form>
            </Box>
            {!props.firsttimeClicked ? 
            <Box className={classes.verifiedBox} color="#2ba3d7"><img width="18px" src="https://cdn.zeplin.io/5f3a0bd1d8d64467967b3a48/assets/4A4FD0BD-FD2B-4B8D-9E06-06BAB8B73FD6.svg"/>
                <Typography className={clsx(classes.fontFamily,classes.italic)}>verified</Typography></Box>
            :''}
            
            
            <Box className={classes.powered} marginBottom={5}>
                <Typography className={classes.fontFamily}>Powered by Mapzot</Typography>
            </Box>
        </Box>
    );

};