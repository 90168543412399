import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { positions } from '@material-ui/system';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button'
import clsx from 'clsx';
import success from '../Success.svg'

const useStyles = makeStyles({
    easyHeadingColor: {
        color: '#279bce',
    },
    fontFamily: {
        fontFamily: 'Montserrat !important',
    },
    box: {
        width: '60vw',
        height: '85vh',
        boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.16)',
        backgroundColor: '#ffffff',
    },
    blueLine: {
        width: '60vw',
        height: '8px',
        backgroundColor: '#2ba3d7',
    },
    fillInfo: {
        width: '60vw',
        height: '50px',
        opacity: '0.1',
        backgroundColor: '#2ba3d7',
    },
    fillInfoText: {
        fontSize: '16px',
        fontWeight: '600',
        lineHeight: '1.19',
        color: '#2ba3d7',
        position: 'relative',
        bottom: '35px'
    },
    buttonCss: {
        fontWeight: '600',
        fontSize: '14px',
        position:'relative',
        top:'-75px',
        padding: '10px 30px',
        borderRadius: '3px',
        backgroundImage: 'linear-gradient(280deg ,#69ccd9 , #2ba3d7 )',

    },
    powered: {
        position: 'relative',
        height: '40px',
        color: '#fff',
        paddingTop: '20px',
        backgroundColor: '#555f8d',
        bottom: '30px',
    },
    yourSite: {
        fontSize: '20px',
        lineHeight: '1.5',
        wordSpacing: '2px',
        position:'relative',
        top:'-25%',
    },
    heightSuccess:{
        height:'25vh',
    }
});



export default function Thankyou() {
    const classes = useStyles();
    return (
        <Box className={clsx(classes.box)}  marginTop={5} spacing={3} align="center">
            <img width="160px" src="https://cdn.zeplin.io/5f3a0bd1d8d64467967b3a48/assets/24C7C6D6-85E4-4304-B709-9E97C3BFCA37.png"/>
            <Box>
                <Box className={classes.blueLine}></Box>
                <Box className={classes.fillInfo}></Box>
                <Typography className={clsx(classes.fillInfoText, classes.fontFamily)}>
                    Success!
                    </Typography>
            </Box>


            <Box marginTop={5} className={clsx(classes.fillInfo,classes.heightSuccess)}>
            </Box>
                <Box color="#3ba562 " className={classes.yourSite}>
                <img src={success} />
                    <Typography>Someone from our team will review this<br />
                    Information and reach out to you!</Typography></Box>
          

            <Button className={classes.buttonCss}>HomePage</Button>

            <Box className={classes.powered}>
                <Typography className={classes.fontFamily}>Powered by Mapzot</Typography>
            </Box>
        </Box>
    );

};