import React, { useState } from 'react';
import EasySubmit from './PopUps/EasySubmit';
import FillTheInfo from './PopUps/FillTheInfo';
import Box from '@material-ui/core/Box';
import UploadFile from './PopUps/UploadFile';
import Thankyou from './PopUps/Thankyou';
import Success from './PopUps/Success';
import Maps from './PopUps/Maps';
import OtpSection from './PopUps/OtpSection'

function App() {
  const [display, setDisplay] = useState({
    easySubmit: true,
    map: false,
    fillTheInfo: false,
    uploadFile: false,
    thankyou: false,
    success: false,
    OtpSection: false,
  })
  const [value, searchValue] = useState('')
  const [latlng, setlatlng] = useState([{ lat: 43.0020498, lng: -85.5935092 }, { lat: 42.5775517, lng: -83.1103726 }])
  const [firsttimeClicked,setClicked]=useState(true)
  const [zoom, setZoom] = React.useState(false);

  return (
    <Box align="center" marginTop={5}>
      {display.fillTheInfo && <FillTheInfo setDisplay={setDisplay} display={display} zoom={zoom} setZoom={setZoom}/>}
      {display.OtpSection && <OtpSection setDisplay={setDisplay} display={display}  zoom={zoom} setClicked={setClicked}/>}
      {display.easySubmit && <EasySubmit setDisplay={setDisplay} display={display} firsttimeClicked={firsttimeClicked} zoom={zoom} setZoom={setZoom} searchValue={searchValue} />}
      {display.map && <Maps setDisplay={setDisplay} display={display} latlng={latlng} value={value} />}
      {(display.uploadFile && value === '2183 E Beltline Avenue Northeast, Grand Rapids, MI, USA') && <UploadFile setDisplay={setDisplay} display={display} />}
      {(display.thankyou && value === '3986 John R Road, Troy, MI, USA') && <Thankyou setDisplay={setDisplay} display={display} />}

      {display.success && <Success setDisplay={setDisplay} display={display} />}
    </Box>
  );
}

export default App;
