import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import OtpInput from 'react-otp-input';
import { positions } from '@material-ui/system';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper';
import Zoom from '@material-ui/core/Zoom';
import clsx from 'clsx';
import success from '../Success.svg'

const useStyles = makeStyles({
    resendOTPColor: {
        color: '#2ba3d7',
        left: '120px',
        position: 'relative',
        top: '-5px',
    },
    fontFamily: {
        fontFamily: 'Montserrat !important',
    },
    box: {
        width: '60vw',
        height: '65vh',
        boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.16)',
        backgroundColor: '#ffffff',
    },
    blueLine: {
        width: '60vw',
        height: '8px',
        backgroundColor: '#2ba3d7',
    },
    fillInfo: {
        width: '60vw',
        height: '50px',
        opacity: '0.1',
        backgroundColor: '#2ba3d7',
    },
    fillInfoText: {
        fontSize: '16px',
        fontWeight: '600',
        lineHeight: '1.19',
        color: '#2ba3d7',
        position: 'relative',
        bottom: '35px'
    },
    buttonCss: {
        fontWeight: '600',
        fontSize: '14px',
        position: 'relative',
        top: '70px',
        margin: '0px 5px',
        left: '-55px',
        padding: '8px 15px',
        borderRadius: '3px',
        backgroundImage: 'linear-gradient(280deg ,#69ccd9 , #2ba3d7 )',

    },
    previousButtn: {
        backgroundColor: "#fff !important",
        border: 'solid 2px #2ba3d7 !important',
        backgroundImage: 'none !important'
    },
    powered: {
        position: 'relative',
        height: '40px',
        color: '#fff',
        paddingTop: '20px',
        backgroundColor: '#555f8d',
        bottom: '-110px',
    },
    input: {
        padding: '8px',
        margin: '35px 20px',
        width: '8%',
        outline: 'none',
        border: 'none',
        borderBottom: 'solid 2px',
    },
    fontBold: {
        fontWeight: 'bold',
        fontSize: '20px',
    },
    otpWidth: {
        width: '20vw',
        marginTop: '10px',

    },
    errorStyles: {
        color: 'red',
        fontSize: '11px',
        marginLeft: '2px'
    },
    paper: {
        width: '60vw',
        height: '65vh',
    },
    padding:{
        padding: '10px 35px !important'
    },

});



export default function Thankyou(props) {
    const classes = useStyles();
    const [OTP, setOTP] = useState("");
    const [wrongOtp, setWrongOtp] = useState(false)

    const previousClick = (e) => {
        e.preventDefault()
        props.setDisplay({ ...props.display, fillTheInfo: true, OtpSection: false })
    }

    const nextClick = (e) => {
        if (OTP === '0000') {
            props.setClicked(false)
            e.preventDefault()
            props.setDisplay({ ...props.display, easySubmit: true, OtpSection: false })
            console.log("puter")
            setWrongOtp(false)
        }
        else {
            setWrongOtp(true)

        }
    }

    return (

        <Box className={clsx(classes.box)} marginTop={10} spacing={3} align="center">

            <Zoom in={props.zoom}>
                <Paper elevation={0} className={classes.paper}>
                    <Box className={classes.blueLine}></Box>
                    <Box className={classes.fillInfo}></Box>
                    <Typography className={clsx(classes.fillInfoText, classes.fontFamily)}>
                        Enter OTP
                    </Typography>

                    <Typography className={clsx(classes.fontBold, classes.fontFamily)}>OTP has sent to your registered<br />
                        mobile number</Typography>
                    <Grid >
                        <Grid item xs={12} className={classes.otpWidth}>
                            <OtpInput
                            shouldAutoFocus={true}
                                value={OTP}
                                onChange={setOTP}
                                numInputs={4}
                                inputStyle={classes.input}

                            />
                        </Grid>
                        {console.log(typeof (OTP))}
                    </Grid>
                    {/* <ResendOTP onResendClick={() => console.log("Resend clicked")} / */}
                    {wrongOtp ? <div className={classes.errorStyles}>Error-Wrong OTP</div> : ""}
                    <Button className={classes.resendOTPColor}>Resend OTP</Button>


                    <Button onClick={previousClick} className={clsx(classes.buttonCss, classes.previousButtn)}>Previous</Button>
                    <Button onClick={nextClick} className={clsx(classes.buttonCss,classes.padding)}>Next</Button>

                    <Box className={classes.powered}>
                        <Typography className={classes.fontFamily}>Powered by Mapzot</Typography>
                    </Box>


                </Paper>
            </Zoom>
        </Box>

    );

};