import React, { useState, useEffect } from 'react';
import { makeStyles ,withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button'
import clsx from 'clsx';
import { validateRegisterInput } from '../validator'
import MuiPhoneNumber from "material-ui-phone-number";
import Paper from '@material-ui/core/Paper';
import Zoom from '@material-ui/core/Zoom';


const GreenCheckbox = withStyles({
    root: {
      '&$checked': {
        color: '#2ba3d7',
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);
  

const useStyles = makeStyles((theme) => ({
    underline: {
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        },
    },
    easyHeadingColor: {
        color: '#279bce',
    },
    fontFamily: {
        fontFamily: 'Montserrat !important',
    },
    box: {
        width: '65vw',
        height: '89vh',
        boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.16)',
        backgroundColor: '#ffffff',
    },
    blueLine: {
        width: '65vw',
        height: '8px',
        backgroundColor: '#2ba3d7',
    },
    fillInfo: {
        width: '65vw',
        height: '50px',
        opacity: '0.1',
        backgroundColor: '#2ba3d7',
    },
    fillInfoText: {
        fontSize: '16px',
        fontWeight: '600',
        lineHeight: '1.19',
        color: '#2ba3d7',
        position: 'relative',
        bottom: '35px'
    },
    marginForm25: {
        float: 'left',
        width: '20%',
        marginTop: '10px',
    },
    marginForm75: {
        float: 'left',
        width: '80%',
        marginTop: '10px'
    },
    paddingLeft: {
        paddingLeft: '60px',
    },
    inputBox: {
        width: '100%',
        height: '32px',
        borderRadius: '3px',
        border: 'solid 1px #707070 !important',
        backgroundColor: '#fff',
        padding: '0 10px',
        outline: 'none'
    },
    labelCss: {
        fontSize: '14px',
        fontWeight: '600',
    },
    inline: {
        display: 'inline-flex',
        width: '82%',
        float: 'right',
    },
    checkboxCss: {
        fontSize: '12px',
    },
    buttonCss: {
        margin: '20px 10px 0 5px',
        width: '100px',
        height: '40px',
        position: 'relative',
        left: '57%',
        top: '-29px',
        borderRadius: '3px',
        backgroundImage: 'linear-gradient(280deg ,#69ccd9 , #2ba3d7 )',

    },
    previousButtn: {
        backgroundColor: "#fff !important",
        border: 'solid 2px #2ba3d7 !important',
        backgroundImage: 'none !important',
        top: '32px !important',
        left: '40% !important',
    },
    powered: {
        position: 'relative',
        height: '40px',
        color: '#fff',
        paddingTop: '20px',
        backgroundColor: '#555f8d',
        bottom: '-90px',
    },
    errorStyles: {
        color: 'red',
        fontSize: '11px',
        marginLeft: '2px'
    },
    paddingPhone: {
        padding: '0 40px !important',
        width: '88%',
    },
    marginMinus: {
        marginTop: '-18px !important',
    },
    paper: {
        width: '65vw',
        height: '89vh',
    },

}));

const captcha = () => {
    console.log('captcha loaded')

}



export default function EasySubmit(props) {

    const [nameValue, setnameValue] = useState('');
    const [lastNameValue, setlastNameValue] = useState('')
    const [emailValue, setemailValue] = useState('')
    const [companyValue, setcompanyValue] = useState('')
    const [phoneValue, setphoneValue] = useState('')
    const [titleValue, settitleValue] = useState('');
    const [checked, setchecked] = useState(false)
    const [error, setError] = useState({});



    const handleOnChange = (value) => {
        if (value) {
            setphoneValue({ phone: value });
        }
    }

    const onCancel = (e) => {
        e.preventDefault()
        props.setDisplay({ ...props.display, fillTheInfo: false, easySubmit: true })
    }

    const onSubmit = (e) => {

        setError({})
        const result = validateRegisterInput({
            firstname: nameValue,
            lastname: lastNameValue,
            company: companyValue,
            title: titleValue,
            userEmail: emailValue,
            checkbox: checked,
        })
        if (!result.isValid) {
            setError(result.errors)
            if (result.errors.checkbox) alert('Please accept our terms and conditions')
        }
        else {
            props.setDisplay({ ...props.display, fillTheInfo: false, OtpSection: true })
        }

    }

    const classes = useStyles();
    return (
        <Box className={clsx(classes.box)} spacing={3} align="center">
            <Zoom in={props.zoom}>
                <Paper elevation={0} className={classes.paper}>

                    <Box>
                        <Box className={classes.blueLine}></Box>
                        <Box className={classes.fillInfo}></Box>
                        <Typography className={clsx(classes.fillInfoText, classes.fontFamily)}>
                            Fill the following information
                    </Typography>
                        <Box align="justify" width="50vw" height="50vh" marginTop={3} className={classes.fontFamily}>
                            <form >
                                <Box display="flex" justifyContent="space-between" >
                                    <Box width="11vw" marginLeft={0}>
                                        <label htmlfor="fname" className={classes.labelCss}>
                                            First name
                                    <span className={classes.errorStyles}>*</span>
                                        </label>
                                    </Box>
                                    <Box width="13vw" marginLeft={-5}>
                                        <input type="text" id="fname" name="fname"
                                            placeholder="John"
                                            value={nameValue}
                                            onChange={(e) => setnameValue(e.target.value)}
                                            className={classes.inputBox} /><br />
                                        <div className={classes.errorStyles}>{error.name}</div>
                                    </Box>

                                    <Box width="11vw" marginLeft={0}>
                                        <label htmlfor="fname" className={clsx(classes.labelCss, classes.paddingLeft)}>
                                            Last name
                                    <span className={classes.errorStyles}>*</span>
                                        </label>
                                    </Box>
                                    <Box width='12vw'>
                                        <input type="text" id="lname" name="fname"
                                            placeholder="Smith"
                                            value={lastNameValue}
                                            onChange={(e) => setlastNameValue(e.target.value)}
                                            className={classes.inputBox} /><br />
                                        <div className={classes.errorStyles}>{error.lastname}</div>
                                    </Box>
                                </Box>

                                <Box className={classes.marginForm25}>
                                    <label htmlfor="lname" className={classes.labelCss}>
                                        Company
                                    <span className={classes.errorStyles}>*</span>
                                    </label>
                                </Box>
                                <Box className={classes.marginForm75}>
                                    <input type="text"
                                        placeholder="Your company name"
                                        value={companyValue}
                                        onChange={(e) => setcompanyValue(e.target.value)}
                                        className={classes.inputBox} /><br />
                                    <div className={classes.errorStyles}>{error.company}</div>
                                </Box>

                                <Box className={classes.marginForm25}>
                                    <label htmlfor="lname" className={classes.labelCss}>
                                        Title
                                    <span className={classes.errorStyles}>*</span></label>
                                </Box>
                                <Box className={classes.marginForm75}>
                                    <input type="text"
                                        placeholder="Your designation"
                                        value={titleValue}
                                        onChange={(e) => settitleValue(e.target.value)}
                                        className={classes.inputBox} /><br />
                                    <div className={classes.errorStyles}>{error.title}</div>
                                </Box>
                                <Box className={classes.marginForm25}>
                                    <label htmlfor="lname" className={classes.labelCss}>Phone

                            <span className={classes.errorStyles}>*</span>
                                    </label>
                                </Box>
                                <Box className={clsx(classes.marginForm75)}>
                                    <MuiPhoneNumber
                                        defaultCountry={'us'}
                                        onChange={handleOnChange}
                                        name="phone"
                                        placeholder="(+1)1234567890"
                                        value={phoneValue}
                                        defaultValue="default text"
                                        InputProps={{ classes }}
                                        className={clsx(classes.inputBox)} /><br />
                                    <div className={classes.errorStyles}>{error.mobile}</div>
                                </Box>

                                <Box className={classes.marginForm25}>
                                    <label htmlfor="lname" className={classes.labelCss}>
                                        Email
                                <span className={classes.errorStyles}>*</span></label>
                                </Box>
                                <Box className={classes.marginForm75}>
                                    <input type="text" id="Email"
                                        placeholder="johnsmith@gmail.com"
                                        value={emailValue}
                                        onChange={(e) => setemailValue(e.target.value)}
                                        className={classes.inputBox} /><br />
                                    <div className={classes.errorStyles}>{error.email}</div>
                                </Box>

                                <Box className={classes.inline} align="center">
                                   <GreenCheckbox name="checkedG" 
                                        label="Custom color"
                                        onChange={() => { setchecked(true) }}
                                    />

                                    <Box paddingTop={2} className={classes.checkboxCss}>I agree with terms and conditions.</Box>

                                </Box>
                                <Button onClick={onCancel} className={clsx(classes.buttonCss, classes.previousButtn)}>Cancel</Button>
                                <Button onClick={onSubmit} className={classes.buttonCss}>Next</Button>
                            </form>


                        </Box>

                    </Box>

                    <Box className={classes.powered}>
                        <Typography className={classes.fontFamily}>Powered by Mapzot</Typography>
                    </Box>

                </Paper>
            </Zoom>
        </Box>
    );

};